$drag-colour: #fff;

.before-after {
    position: relative;

    .cocoen:before {
        background-color: rgba(0,0,0,0.4);
        position: absolute;
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
    }


    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        div {
            text-align: center;
            font-weight: 600;
            font-size: clamp(0.8rem,2vw,1.5rem);
        }
    }

    @media screen and (min-width: $size__mobile){
        .wrapper {
            position: absolute;
            z-index: 3;
            display: flex;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            color: #fff;
            pointer-events:none;
            width: 100%;
            height: 100%;
        }
        .flex {
            flex-direction: row;
        }


        .flex div {
            padding: $size__spacing/2;
            text-shadow: 0 0 5px #000,0 0 4px #000;
        }
    }
}

.cocoen-drag {
    z-index: 4;

    &::before {
        border-radius: 50%;
        width: 40px !important;
        height: 40px !important;
        left: -11px !important;
        top: 57% !important;
        background: url(../images/arrows.png) no-repeat 50% 50%;
        background-size: contain;
        padding: 3px;

        @media screen and (min-width: $size__mobile){
            top: 45% !important;
            width: 50px !important;
            height: 50px !important;
            left: -17px !important;
        }
    }
}

.cocoen-2 .cocoen-drag{
    background-color: transparent !important;

    &:before {
        top:83% !important;
    }
}

.cocoen,.cocoen-2 {
    box-sizing: border-box;
    cursor: pointer;
    line-height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    user-select: none;

    * {
        box-sizing: inherit;

        &::after,
        &::before {
            box-sizing: inherit;
        }
    }

    img,
    picture > img {
        max-width: none;
    }

    > img,
    > picture > img {
        display: block;
        width: 100%;
    }

    > div {
        &:first-child,
        picture & {
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 50%;
        }
    }

    &-drag {
        background: $drag-colour;
        bottom: 0;
        cursor: ew-resize;
        left: 50%;
        margin-left: -1px;
        position: absolute;
        top: 0;
        width: 2px;

        &::before {
            border: 3px solid $drag-colour;
            content: '';
            height: 30px;
            left: 50%;
            margin-left: -7px;
            margin-top: -18px;
            position: absolute;
            top: 50%;
            width: 14px;
        }
    }
}

.cocoen-2 img:first-child {
    position: relative;
    left: 11px;
    z-index: 3;
}