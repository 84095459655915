.grid-images {
	margin-top: $size__spacing;
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: repeat(auto-fill ,minmax(250px,1fr) );
	align-items: stretch;

	img {
		object-fit: cover;
	}

	@media screen and (min-width: 840px){
		img.two-rows {
			grid-row: 1/3;
			grid-column: 3;
		}
	}
}

.infographics-grid {
	display: flex;
	flex-wrap: wrap;
	max-width: 1400px;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;

	img {
		flex-basis: 32%;
		flex-grow: 1;
		min-width: 250px;
		max-width: 500px;
	}
}


.player-buttons {
    height: 100px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 100px;
    z-index: 1;
    z-index: 9;
    transform: translate(-50%,-50%);
}
