.cta {
	grid-column: 1/-1;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	margin-bottom: 50px;
}

.button {
	display: inline-block;
	border-radius: 200px;
	background-color: var(--brand-green);
	padding: 1em 3em;
	color: var(--brand-white);
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 800;
	text-align: center;
	letter-spacing: 0.09em;
	border: none;
	border: 3px solid #fff;
	transition: all 300ms;

	&:hover,&:active {
		box-shadow: 0 0 5px rgba(var(--brand-green),0.25);
		border-color: var(--brand-green);
	}
}

.toggle-button,.menu-toggle {
	z-index: 100;

	@media screen and (min-width: $size__mobile){
		display: none;
	}
}

.lines {
	position: relative;
	vertical-align: middle;
	top: -2px;

	&,&:before,&:after {
		transition: all 500ms;
		display: inline-block;
		border-radius: 2px;
		background-color: #fff;
		height: 3px;
		width: 20px;
		content: '';
	}

	&:before,&:after {
		position: absolute;
	}

	&:before {
		top: -5px;
	}
	&:after {
		bottom: -5px;
	}
}

.toggled .lines {
	&:after {
		opacity: 0;
		bottom: 0;
	}

	& {
		transform: rotate(45deg);
	}
	&:before {
		top: 0;
		transform: rotate(90deg)
	}
}


.main-navigation {
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 99;
	background-color: #fff;
	transform: translateX(100%);
	transition: transform 300ms;
	min-width: 320px;
	padding: 30px 50px;
	padding-top: 100px;


	hr {
		border: none;
		background-color: var(--brand-green);
		height: 1px;
		margin: 30px 0;
	}

	.toggled & {
		box-shadow: 0px 0 20px rgba(0,0,0,0.1);
		transform: translateX(0);
	}

	a {
		display: block;
		font-size: 2rem;
		color: var(--brand-green);
		transition: color 300ms;

		&:hover,&:active,&:focus {
			color: var(--brand-blue);
		}
	}

	.email {
		font-weight: bold;
	}

}