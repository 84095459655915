@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;800&&display=swap');
@font-face {
    font-family: 'Cookie';
    src: url('../fonts/cookieregular-ow3ov-webfont.woff2') format('woff2'),
         url('../fonts/cookieregular-ow3ov-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@import "./variables/variables.scss";
@import "./mixins/mixin.scss";
@import "./components/header.scss";
@import "./components/buttons.scss";
@import "./components/form.scss";
@import "./components/compare.scss";
@import "./components/media.scss";
@import "./components/flip.scss";
@import "./components/nav.scss";

*,
*::after,
*::before {
    box-sizing: border-box;
}

::-webkit-scrollbar{
   width: .6rem;
   background-color: var(--brand-white);
}

::-webkit-scrollbar-thumb{
   background: var(--brand-light-green);
}

html,body {
    font-family: "Montserrat";
    margin: 0;
    padding: 0;
}
img,video,svg {
    max-width: 100%;
}

.video-header {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

    h1 {
        display: none;
    }

    video {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }


    section {
        @extend %container;
        text-align: center;
        position: relative;
        z-index: 2;

    }

    img {
        max-width: 500px;
        max-height: 400px;
        object-fit: contain;

        @media screen and (min-width: $size__mobile){
            margin-top: 100px;
        }
    }

    p {
        text-transform: uppercase;
        font-size: clamp(1rem,4vw,2rem);
    }
}

.container {
    @extend %container;
}

main ,.site-footer{
    position: relative;
    z-index: 3;
    background-color: #fff;
}

.counter {
    padding: 4rem 0;
    position: relative;
}

.thin-header {
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    font-size: clamp(1.75rem,6vw,3rem);
    letter-spacing: 0.1em;
}

.polaroid {
    background: url(../images/paper.jpg);
    background-size: cover;
    position: relative;
    z-index: 2;
    text-align: center;
    overflow: hidden;

    .brush {
        font-size: clamp(3rem,9vw,7rem);
        line-height: 1;
        padding-top: 2rem;
        margin-bottom: 0;
    }

    p {
        font-size: clamp(1rem,5vw,2rem);
    }

    img.brand {
        float: left;
        display: block;
        clear: left;
        max-width: 31vw;
    }

    article {
        text-align: right;
        padding-bottom: 100px;
        padding-top: 50px;

        p:first-child {
            margin-top: 0;
        }

        p {
            line-height: 1;
        }

        .small {
            font-size: 1.1em;
            line-height: 1;
            margin-left: auto;
            max-width: 35ch;
        }
    }
}
.amount {
    position: relative;
    padding: 100px 0;

    .container {
        padding: 0;
    }

}

.fixed {
    position: absolute;
    right: 0;
    z-index: 2;
    top: 0;
    transform: translateY(-50%);
    max-width: 40vw;
}

.polaroids {
    text-align: center;
    position: relative;
    padding: 0 1rem;

    figure.photo,.photo figure {
        background-color: #fff;
        padding: 24px 24px 20px;
        box-shadow: 3px 3px 7px rgba(0,0,0,0.35);
        margin: 0;
    }

    .front-photo {
        z-index: 3;
        position: relative;
        max-width: 700px;
    }

    .photo {
        margin-bottom: 0;
        display: inline-block;


        &.back-photo {
            position: absolute;
            z-index: 1;
            padding-bottom: 50px;
            width: max-content;
            top: -2rem;
        }

        &.first-photo {
            left: 0;
            transform: rotate(19deg) translateX(3%);
        }

        &.third-photo {
            right: 0;
            transform: rotate(-20deg) translateX(-3%);
        }
    }


    figcaption {
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
        color: var(--brand-green);
        font-size: clamp(1.2rem,6vw,2.5rem);
        position: relative;top: 10px;
        margin: 0;
    }

    @media screen and (max-width: $size__mobile){
        .third-photo,.first-photo {
            display: none;
        }
    }
}

.brush,.cookie {
    color: var(--brand-green);
    font-family: "Brush Script MT";
    font-size: clamp(2rem,5vw,5rem);
    text-align: center;
    line-height: 1;
}

.cookie {
    font-family: Cookie;
}

.video-player {
    background: url(../images/papier-2.jpg) 50% 50% no-repeat;
    background-size: cover;
    text-align: center;
    padding: $size__spacing 0;
    overflow: hidden;

    .large,.medium,.light {
        color: var(--brand-light-green);
        margin-bottom: 2rem;
        text-transform: uppercase;
    }

    .large,.medium {
        font-weight: 800;
    }

    .light {
        font-weight: 300;
    }

    .large {
        font-size: clamp(1.4rem,4vw,3.7rem);
    }

    .medium, .light {
        font-size: clamp(1rem,4vw,2rem);
    }

    .video-container {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: 7rem;

        video {
            position: relative;
            max-width: 100%;
            z-index: 3;
        }

        &:before,&:after {
            content: '';
            display: block;
            position: absolute;
            width: 25.69rem;
            height: 22.38rem;
            background-image: url(../images/leaf.png);
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 0;
            max-width: 20vw;
        }

        &:before {
            transform:scaleX(-1);
            bottom: -10%;
            left: -20%;
        }
        &:after {
            top: -7rem;
            right: -12rem;
        }
    }
}


.green {
    background: url(../images/leafs.svg) 50% 50% no-repeat,url(../images/green.jpg) 50% 50% no-repeat;
    background-size: 50%, cover;
    color: #fff;
    font-size: clamp(1.1rem,4vw,1.8rem);
    text-align: center;
    padding: $size__spacing 0;
    font-weight: 300;
}

.green-bg {
    background-color: var(--brand-light-green);
    color: var(--brand-white);
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.12em;
    font-size: clamp(1.2rem,5vw,2rem);
    text-transform: uppercase;
    padding: 1rem;
}

.site-footer {

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        img {
            max-width: 30vw
        }

        img + img {
            margin-top: 1rem;
        }
    }

    nav.container {
        text-align: center;
        padding: 10px;
        display: block;
    }

    a {
        text-decoration: none;
        color: inherit;
        font-weight: bold;
    }
}

.content {
    line-height: 1.3;
    margin-top: $size__spacing;
}

.callendar{
    img{
        object-fit:cover;
        height: 100%;
        width: 100%;
    }

    .content{
        display: none;

    }

    .head,.row{
        display: grid;
        grid-template-columns: repeat(7,1fr );
        grid-gap: 5px;
        margin-bottom: 10px;
    }

     .row div{
            height: clamp(60px,8vw,100px);
            overflow: hidden;
            cursor: pointer;
    }

    .head{
            margin-bottom: 20px;

            div{
                background-color: #000;
                color: #fff;
                padding: 10px;
                font-weight: 800;
                text-align: center;
                font-size: clamp(0.6rem,2vw,2rem);

            }
        }

        .row{
                position: relative;

                &:before{
                    font-weight: 800;
                    position: absolute;
                    z-index: 9;
                    pointer-events: none;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: clamp(1rem,5vw,3rem);
                    color: #fff;
                    text-shadow: 0 0 4px #000;
                    text-transform: uppercase;
                    margin: 0;
                    padding: 0;
                }
            }

            .callendar-1:before{
                content: 'na gotowanie';
                background-color: rgba(64,48,6,0.57);
            }.callendar-2:before{
                content: 'na podróżowanie';
                background-color: rgba(255,255,0,0.57);
            }.callendar-3:before{
                content: 'na dbanie o higienę';
                background-color: rgba(0,222,255,0.57);
            }.callendar-4:before{
                content: 'na sprzątanie';
                background-color: rgba(0,255,42,0.57);
            }.callendar-5:before{
                content: 'na co dzień';
                background-color: rgba(87,87,87,0.57);
            }

}

.for-nature{

    .brand{
        background-color: #ebebeb;
        margin-bottom: 40px;
        padding: 0;

        .container {
            position: relative;
            text-align: center;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 30vw;
            max-width: 250px;
        }

        h2 {
            padding: 5px 100px;
        }
    }

    .with-bg {
        display: flex;
        justify-content: center;

        .content {
            color: var(--brand-green);
            text-align: center;
            max-width: 60ch;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;

            p {
                font-size: clamp(1rem,2vw,1.2rem);
            }

        }

        @media screen and (max-width: $size__mobile){
            background-image: none;
            min-height: unset;
            padding-top: 0;
        }
    }

}

.modal{
    position: fixed;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(0,0,0,0.3);
    z-index: 99;
    max-width: 80vw;
    margin:0 20px;
    padding: 20px;
    top: 5vh;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events:none;
    transition: opacity 300ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;

    .close{
        border: none;
        font-weight: 800;
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 3px;
        display: block;
        z-index: 10;
        width: 25px;height: 25px;
    }

    .wrap{
        overflow: auto;
        position: relative;
        z-index: 3;
    }

    header{
        height: 50%;
        min-height: 150px;
        max-height: 550px;
        overflow: hidden;
        position: relative;

        &::after{
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.5;
            background-color: rgb(64,48,6);
        }

        img{
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &.visible{
        opacity: 1;
        pointer-events:all;
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        span {
            position: absolute;
            left: 50%;
            z-index: 0;
            color:#f4f4f4;
            font-weight: 800;
            font-size: 5rem;
            transform:translateX(-50%);
            top: -6rem;
        }

        button {
            position: relative;
            z-index: 1;
            border: none;
            background-color: var(--brand-green);
            height: 30px;
            width: 50px;
            display: inline-block;
            margin: 3px;
            color: #fff;
            border-radius: 3px;
            transition: background-color 200ms, color 200ms;
            outline:none;
        }


    }
    .section1 button, header.section1::after {
        background-color: rgb(64,48,6);
    }
    .section2 button,header.section2::after {
        background-color: rgb(255,255,0);
        color: rgba(87,87,87);
    }
    .section3 button,header.section3::after {
        background-color: rgb(0,222,255);
    }
    .section4 button,header.section4::after {
        background-color: rgb(0,255,42);
    }
    .section5 button,header.section5::after {
        background-color: rgba(87,87,87);
    }

    @media screen and (max-width: $size__mobile){
        left: 10px;
        transform: translateX(0);
        top: 10px;
        .inner {
            overflow: scroll;
        }
    }
}

.eko {
    padding: 50px 20px;

    h2 {
        font-size: 2rem;
        color: var(--brand-green);

        span {
            color: #c1c6ca;
        }
    }

    @media screen and (max-width: $size__mobile){
        overflow: scroll;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.carousel {
    display: grid;
    grid-row-gap: 2rem;
    grid-template-columns: repeat(auto-fit ,minmax(250px,1fr) );
    padding: 4rem 0;
}

.item {
    border-radius: 2px;
    box-shadow: 5px 6px 6px rgba(0,0,0,0.11);
    padding: 10px;
    border: 1px solid #ebebeb;
    margin: 0 20px;

    h4 {
        color: var(--brand-green);
    }

    figure {
        text-align: center;
    }

    p {
        font-size: 0.9em;
    }
}

.amount-value {
    font-family: Cookie;
    font-size: 3rem;
    text-align: center;
    color: var(--brand-green);

    margin-top: 1rem;
}


.numbers{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .number{
        flex-basis: 25%;
        padding: 1rem;
        min-width: 250px;
        flex-grow: 1;
    }

    span{
        display: block;
        font-size: 3rem;
        font-weight: bold;
    }
}