@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;800&&display=swap");
@font-face {
  font-family: "Cookie";
  src: url("cookieregular-ow3ov-webfont.169f8902.woff2") format("woff2"), url("cookieregular-ow3ov-webfont.8af5e931.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Global variables */
/* ======================================================================== */
/* Grid */
/* ======================================================================== */
/* Form */
/* ======================================================================== */
/* Breakpoints */
:root {
  --brand-blue: #0b77b9;
  --brand-green: #407c3b;
  --brand-light-green: #699013;
  --brand-white: #fff;
  --brand-lightgray: #F7F7F7;
  --brand-gray: #A7A8A8;
  --brand-black: #000;
}
.container, .video-header section {
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
}
.cta {
  grid-column: 1/-1;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  margin-bottom: 50px;
}
.button {
  display: inline-block;
  border-radius: 200px;
  background-color: var(--brand-green);
  padding: 1em 3em;
  color: var(--brand-white);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  letter-spacing: 0.09em;
  border: none;
  border: 3px solid #fff;
  transition: all 300ms;
}
.button:hover, .button:active {
  box-shadow: 0 0 5px rgba(var(--brand-green), 0.25);
  border-color: var(--brand-green);
}
.toggle-button, .menu-toggle {
  z-index: 100;
}
@media screen and (min-width: 35em) {
  .toggle-button, .menu-toggle {
    display: none;
  }
}
.lines {
  position: relative;
  vertical-align: middle;
  top: -2px;
}
.lines, .lines:before, .lines:after {
  transition: all 500ms;
  display: inline-block;
  border-radius: 2px;
  background-color: #fff;
  height: 3px;
  width: 20px;
  content: "";
}
.lines:before, .lines:after {
  position: absolute;
}
.lines:before {
  top: -5px;
}
.lines:after {
  bottom: -5px;
}
.toggled .lines:after {
  opacity: 0;
  bottom: 0;
}
.toggled .lines {
  transform: rotate(45deg);
}
.toggled .lines:before {
  top: 0;
  transform: rotate(90deg);
}
.main-navigation {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 300ms;
  min-width: 320px;
  padding: 30px 50px;
  padding-top: 100px;
}
.main-navigation hr {
  border: none;
  background-color: var(--brand-green);
  height: 1px;
  margin: 30px 0;
}
.toggled .main-navigation {
  box-shadow: 0px 0 20px rgba(0, 0, 0, 0.1);
  transform: translateX(0);
}
.main-navigation a {
  display: block;
  font-size: 2rem;
  color: var(--brand-green);
  transition: color 300ms;
}
.main-navigation a:hover, .main-navigation a:active, .main-navigation a:focus {
  color: var(--brand-blue);
}
.main-navigation .email {
  font-weight: bold;
}
fieldset {
  border: none;
  min-width: 220px;
}
.form {
  background: var(--brand-lightgray);
  margin-left: -2rem;
  margin-right: -2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media screen and (max-width: 35em) {
  .form {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.form__row {
  margin-bottom: 15px;
}
.form__row .btn,
.form__row input:not([type]),
.form__row input[type=datetime],
.form__row input[type=email],
.form__row input[type=number],
.form__row input[type=password],
.form__row input[type=search],
.form__row input[type=tel],
.form__row input[type=text],
.form__row input[type=url],
.form__row textarea {
  display: block;
}
.form__row textarea {
  max-width: 100%;
  min-height: 15px;
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
}
input:not([type]),
input[type=datetime],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
textarea, select {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 6px 15px;
  outline: none;
  font-size: 16px;
  font-weight: 300;
  font-family: "Open Sans";
  border-radius: 100px;
  transition: all 0.3s;
}
input:not([type]):hover,
input[type=datetime]:hover,
input[type=email]:hover,
input[type=number]:hover,
input[type=password]:hover,
input[type=search]:hover,
input[type=tel]:hover,
input[type=text]:hover,
input[type=url]:hover,
textarea:hover, select:hover {
  border: 1px solid #ddd;
  background-color: #fff;
}
input:not([type]):focus,
input[type=datetime]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=text]:focus,
input[type=url]:focus,
textarea:focus, select:focus {
  border: 1px solid #ddd;
  background-color: #fff;
}
input:not([type]).invalid,
input[type=datetime].invalid,
input[type=email].invalid,
input[type=number].invalid,
input[type=password].invalid,
input[type=search].invalid,
input[type=tel].invalid,
input[type=text].invalid,
input[type=url].invalid,
textarea.invalid, select.invalid {
  background-color: #fff0f0;
  border: 1px solid red;
}
input:not([type])::-webkit-input-placeholder,
input[type=datetime]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=text]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder, select::-webkit-input-placeholder {
  color: #ddd;
}
input:not([type]):-moz-placeholder,
input[type=datetime]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=number]:-moz-placeholder,
input[type=password]:-moz-placeholder,
input[type=search]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=text]:-moz-placeholder,
input[type=url]:-moz-placeholder,
textarea:-moz-placeholder, select:-moz-placeholder {
  color: #ddd;
}
input:not([type])::-moz-placeholder,
input[type=datetime]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=number]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=search]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=text]::-moz-placeholder,
input[type=url]::-moz-placeholder,
textarea::-moz-placeholder, select::-moz-placeholder {
  color: #ddd;
}
input:not([type]):-ms-input-placeholder,
input[type=datetime]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=search]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=text]:-ms-input-placeholder,
input[type=url]:-ms-input-placeholder,
textarea:-ms-input-placeholder, select:-ms-input-placeholder {
  color: #ddd;
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/* Для Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* Для Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input[type=checkbox],
input[type=radio] {
  padding: 0;
  display: inline-block;
  height: 14px;
  width: 14px;
  border: 1px solid #aaa;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: 0;
  background: 0 0;
  text-align: center;
  position: relative;
  flex: none;
}
input[type=checkbox]:not(:disabled), input[type=checkbox]:not(:disabled),
input[type=radio]:not(:disabled),
input[type=radio]:not(:disabled) {
  cursor: pointer;
}
input[type=checkbox]:before, input[type=checkbox]:before,
input[type=radio]:before,
input[type=radio]:before {
  display: block;
}
input[type=checkbox]:disabled, input[type=checkbox]:disabled,
input[type=radio]:disabled,
input[type=radio]:disabled {
  border-color: #ddd;
}
input[type=radio] {
  border-radius: 50%;
}
input[type=radio]:checked:before {
  content: "";
  width: 6px;
  height: 6px;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  display: block;
  position: absolute;
  border-radius: 50%;
  background: #000;
}
input[type=radio]:disabled:checked:before {
  background-color: #aaa;
}
input[type=checkbox]:checked:before, input[type=checkbox]:indeterminate:before {
  content: "✔";
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  line-height: 13px;
  color: #000;
}
input[type=checkbox]:indeterminate:before {
  content: "";
}
input[type=checkbox]:disabled:checked:before, input[type=checkbox]:disabled:indeterminate:before {
  color: #aaa;
}
label {
  margin-bottom: 10px;
  display: inline-block;
}
label strong {
  display: block;
  font-weight: 800;
}
select {
  max-width: 100%;
}
.order-form {
  display: flex;
  flex-wrap: wrap;
}
.order-form .form__row {
  flex-basis: 50%;
  flex-grow: 1;
}
@media screen and (min-width: 35em) {
  .order-form .form__row {
    display: flex;
    align-items: center;
    flex-basis: 50%;
    flex-direction: column;
    position: relative;
  }
  .order-form .form__row:first-child {
    padding-right: 2rem;
  }
  .order-form .form__row:first-child:after {
    content: "≈";
    font-size: 3rem;
    position: absolute;
    right: -1rem;
    top: 50%;
  }
  .order-form .form__row + .form__row {
    padding-left: 2rem;
  }
}
.order-form input {
  text-align: center;
  font-weight: 800;
  font-size: 1.2rem;
}
.order-form figure {
  display: flex;
  align-items: center;
}
.contact-form {
  display: flex;
  flex-wrap: wrap;
}
.contact-form h3 {
  margin-bottom: 0.51rem;
}
.contact-form fieldset:first-child {
  flex-basis: 35%;
  flex-grow: 1;
  position: relative;
  margin-right: 3rem;
}
.contact-form fieldset:first-child .form__row figure {
  align-items: center;
  display: flex;
}
.contact-form fieldset:first-child .form__row figure svg {
  margin-right: 1rem;
}
.contact-form fieldset:first-child:before, .contact-form fieldset:first-child:after {
  display: inline-block;
  position: absolute;
  right: 0;
}
.contact-form fieldset:first-child:before {
  content: "lub";
  font-weight: 300;
  top: 2em;
}
.contact-form fieldset:first-child:after {
  height: 70px;
  width: 1px;
  background-color: var(--brand-gray);
  top: 2em;
}
.contact-form fieldset + fieldset {
  flex-basis: 60%;
  padding-left: 3rem;
}
@media screen and (max-width: 35em) {
  .contact-form fieldset {
    margin-right: 0;
    padding-left: 0;
  }
  .contact-form fieldset:first-child:before {
    left: 50%;
    bottom: -1rem;
    top: auto;
  }
  .contact-form fieldset + fieldset {
    padding-left: 0;
    margin-top: 2rem;
  }
}
.before-after {
  position: relative;
}
.before-after .cocoen:before {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  content: "";
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.before-after .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.before-after .flex div {
  text-align: center;
  font-weight: 600;
  font-size: clamp(0.8rem,2vw,1.5rem);
}
@media screen and (min-width: 35em) {
  .before-after .wrapper {
    position: absolute;
    z-index: 3;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  .before-after .flex {
    flex-direction: row;
  }
  .before-after .flex div {
    padding: 2rem;
    text-shadow: 0 0 5px #000, 0 0 4px #000;
  }
}
.cocoen-drag {
  z-index: 4;
}
.cocoen-drag::before {
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  left: -11px !important;
  top: 57% !important;
  background: url(arrows.71305e43.png) no-repeat 50% 50%;
  background-size: contain;
  padding: 3px;
}
@media screen and (min-width: 35em) {
  .cocoen-drag::before {
    top: 45% !important;
    width: 50px !important;
    height: 50px !important;
    left: -17px !important;
  }
}
.cocoen-2 .cocoen-drag {
  background-color: transparent !important;
}
.cocoen-2 .cocoen-drag:before {
  top: 83% !important;
}
.cocoen, .cocoen-2 {
  box-sizing: border-box;
  cursor: pointer;
  line-height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  user-select: none;
}
.cocoen *, .cocoen-2 * {
  box-sizing: inherit;
}
.cocoen *::after, .cocoen *::before, .cocoen-2 *::after, .cocoen-2 *::before {
  box-sizing: inherit;
}
.cocoen img,
.cocoen picture > img, .cocoen-2 img,
.cocoen-2 picture > img {
  max-width: none;
}
.cocoen > img,
.cocoen > picture > img, .cocoen-2 > img,
.cocoen-2 > picture > img {
  display: block;
  width: 100%;
}
.cocoen > div:first-child, picture .cocoen > div, .cocoen-2 > div:first-child, picture .cocoen-2 > div {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 50%;
}
.cocoen-drag, .cocoen-2-drag {
  background: #fff;
  bottom: 0;
  cursor: ew-resize;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  top: 0;
  width: 2px;
}
.cocoen-drag::before, .cocoen-2-drag::before {
  border: 3px solid #fff;
  content: "";
  height: 30px;
  left: 50%;
  margin-left: -7px;
  margin-top: -18px;
  position: absolute;
  top: 50%;
  width: 14px;
}
.cocoen-2 img:first-child {
  position: relative;
  left: 11px;
  z-index: 3;
}
.grid-images {
  margin-top: 4rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  align-items: stretch;
}
.grid-images img {
  object-fit: cover;
}
@media screen and (min-width: 840px) {
  .grid-images img.two-rows {
    grid-row: 1/3;
    grid-column: 3;
  }
}
.infographics-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.infographics-grid img {
  flex-basis: 32%;
  flex-grow: 1;
  min-width: 250px;
  max-width: 500px;
}
.player-buttons {
  height: 100px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 100px;
  z-index: 1;
  z-index: 9;
  transform: translate(-50%, -50%);
}
@keyframes countDown_flip_1 {
  0% {
    transform: rotateX(0deg);
    z-index: 1;
  }
  100% {
    transform: rotateX(-180deg);
    z-index: 0;
  }
}
@keyframes countDown_flip_2 {
  0% {
    transform: rotateX(0deg);
    z-index: 0;
  }
  100% {
    transform: rotateX(-180deg);
    z-index: 1;
  }
}
.counter {
  overflow: hidden;
}
.countDown__cont {
  font-size: clamp(0.2rem,1.5vw,1rem);
  display: flex;
  justify-content: center;
  color: #333;
}
.countDown__interval_cont {
  display: flex;
  justify-content: space-around;
  width: auto;
}
.countDown__interval_cont:nth-child(n+1):not(:last-child) {
  margin-right: clamp(0,1vw,2rem);
}
.countDown__interval_basic_cont {
  display: flex;
  flex-direction: column-reverse;
}
.countDown__interval_basic_cont_description {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(1rem,2vw,2rem);
  margin-bottom: 1rem;
}
.countDown__digit_cont {
  perspective: 3.2em;
  width: 1em;
  height: 1.6em;
  position: relative;
  line-height: 1.6em;
  font-size: 5em;
  font-weight: bold;
  border-radius: 10px;
  border: clamp(1px,0.5vw,5px) solid black;
  margin: 0 0.5em;
}
.countDown__digit_cont:before, .countDown__digit_cont:after {
  content: "";
  position: absolute;
  display: block;
  background-image: url(clockimage.4f3a087c.png);
  width: 7px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.countDown__digit_cont:before {
  left: -8px;
}
.countDown__digit_cont:after {
  right: -11px;
}
.countDown__digit_cont:nth-child(n+1):not(:last-child) {
  margin-right: 0.05em;
}
.countDown__digit_last_placeholder, .countDown__digit_new_placeholder {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  text-align: center;
  overflow: hidden;
}
.countDown__digit_last_placeholder {
  bottom: 0;
  background: white;
  border-radius: 0 0 10px 10px;
}
.countDown__digit_last_placeholder_inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50%;
  position: absolute;
}
.countDown__digit_new_placeholder {
  top: 0;
  background: #f7f7f7;
  border-radius: 10px 10px 0 0;
}
.countDown__digit_last_rotate, .countDown__digit_new_rotate {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50%;
  font-weight: bold;
  position: absolute;
  top: 0;
  overflow: hidden;
  animation-duration: 1s;
  animation-timing-function: linear;
  border-radius: 10px 10px 0 0;
  animation-fill-mode: forwards;
  transform-origin: 100% 100%;
}
.countDown__digit_last_rotate:after, .countDown__digit_new_rotate:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1em solid rgba(0, 0, 0, 0.1);
}
.countDown__digit_last_rotate {
  animation-name: countDown_flip_1;
  background: #f7f7f7;
}
.countDown__digit_new_rotate {
  animation-name: countDown_flip_2;
  background: white;
}
.countDown__digit_new_rotated {
  transform: rotateX(180deg);
  width: 100%;
  height: 100%;
}
.countDown__digit_new_rotated_inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50%;
  position: absolute;
}
.site-header {
  position: fixed;
  z-index: 10;
  background-color: var(--brand-green);
  width: 100%;
}
.site-header a {
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 10px;
  display: inline-block;
  position: relative;
  transition: top 300ms;
  top: 0;
}
.site-header a:hover {
  top: -3px;
}
.site-header nav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
@media screen and (max-width: 35em) {
  .site-header {
    left: 0;
    bottom: 0;
    right: 0;
    transition: transform 400ms;
    transform: translatey(100%);
  }
  .toggled .site-header {
    transform: translateY(0);
  }
  .site-header nav {
    flex-direction: column;
  }
  .site-header nav a {
    margin-bottom: 5px;
  }
}
.menu-toggle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: var(--brand-green);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  color: #fff;
  line-height: 0;
  outline: none;
  transition: all 300ms;
}
.toggled .menu-toggle {
  color: var(--brand-green);
  background-color: #fff;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: var(--brand-white);
}
::-webkit-scrollbar-thumb {
  background: var(--brand-light-green);
}
html, body {
  font-family: "Montserrat";
  margin: 0;
  padding: 0;
}
img, video, svg {
  max-width: 100%;
}
.video-header {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.video-header h1 {
  display: none;
}
.video-header video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.video-header section {
  text-align: center;
  position: relative;
  z-index: 2;
}
.video-header img {
  max-width: 500px;
  max-height: 400px;
  object-fit: contain;
}
@media screen and (min-width: 35em) {
  .video-header img {
    margin-top: 100px;
  }
}
.video-header p {
  text-transform: uppercase;
  font-size: clamp(1rem,4vw,2rem);
}
main, .site-footer {
  position: relative;
  z-index: 3;
  background-color: #fff;
}
.counter {
  padding: 4rem 0;
  position: relative;
}
.thin-header {
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  font-size: clamp(1.75rem,6vw,3rem);
  letter-spacing: 0.1em;
}
.polaroid {
  background: url(paper.3e9ad2d3.jpg);
  background-size: cover;
  position: relative;
  z-index: 2;
  text-align: center;
  overflow: hidden;
}
.polaroid .brush {
  font-size: clamp(3rem,9vw,7rem);
  line-height: 1;
  padding-top: 2rem;
  margin-bottom: 0;
}
.polaroid p {
  font-size: clamp(1rem,5vw,2rem);
}
.polaroid img.brand {
  float: left;
  display: block;
  clear: left;
  max-width: 31vw;
}
.polaroid article {
  text-align: right;
  padding-bottom: 100px;
  padding-top: 50px;
}
.polaroid article p:first-child {
  margin-top: 0;
}
.polaroid article p {
  line-height: 1;
}
.polaroid article .small {
  font-size: 1.1em;
  line-height: 1;
  margin-left: auto;
  max-width: 35ch;
}
.amount {
  position: relative;
  padding: 100px 0;
}
.amount .container {
  padding: 0;
}
.fixed {
  position: absolute;
  right: 0;
  z-index: 2;
  top: 0;
  transform: translateY(-50%);
  max-width: 40vw;
}
.polaroids {
  text-align: center;
  position: relative;
  padding: 0 1rem;
}
.polaroids figure.photo, .polaroids .photo figure {
  background-color: #fff;
  padding: 24px 24px 20px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.35);
  margin: 0;
}
.polaroids .front-photo {
  z-index: 3;
  position: relative;
  max-width: 700px;
}
.polaroids .photo {
  margin-bottom: 0;
  display: inline-block;
}
.polaroids .photo.back-photo {
  position: absolute;
  z-index: 1;
  padding-bottom: 50px;
  width: max-content;
  top: -2rem;
}
.polaroids .photo.first-photo {
  left: 0;
  transform: rotate(19deg) translateX(3%);
}
.polaroids .photo.third-photo {
  right: 0;
  transform: rotate(-20deg) translateX(-3%);
}
.polaroids figcaption {
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  color: var(--brand-green);
  font-size: clamp(1.2rem,6vw,2.5rem);
  position: relative;
  top: 10px;
  margin: 0;
}
@media screen and (max-width: 35em) {
  .polaroids .third-photo, .polaroids .first-photo {
    display: none;
  }
}
.brush, .cookie {
  color: var(--brand-green);
  font-family: "Brush Script MT";
  font-size: clamp(2rem,5vw,5rem);
  text-align: center;
  line-height: 1;
}
.cookie {
  font-family: Cookie;
}
.video-player {
  background: url(papier-2.21ae478e.jpg) 50% 50% no-repeat;
  background-size: cover;
  text-align: center;
  padding: 4rem 0;
  overflow: hidden;
}
.video-player .large, .video-player .medium, .video-player .light {
  color: var(--brand-light-green);
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.video-player .large, .video-player .medium {
  font-weight: 800;
}
.video-player .light {
  font-weight: 300;
}
.video-player .large {
  font-size: clamp(1.4rem,4vw,3.7rem);
}
.video-player .medium, .video-player .light {
  font-size: clamp(1rem,4vw,2rem);
}
.video-player .video-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 7rem;
}
.video-player .video-container video {
  position: relative;
  max-width: 100%;
  z-index: 3;
}
.video-player .video-container:before, .video-player .video-container:after {
  content: "";
  display: block;
  position: absolute;
  width: 25.69rem;
  height: 22.38rem;
  background-image: url(leaf.7046f99c.png);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
  max-width: 20vw;
}
.video-player .video-container:before {
  transform: scaleX(-1);
  bottom: -10%;
  left: -20%;
}
.video-player .video-container:after {
  top: -7rem;
  right: -12rem;
}
.green {
  background: url(leafs.4807eee5.svg) 50% 50% no-repeat, url(green.a01063c3.jpg) 50% 50% no-repeat;
  background-size: 50%, cover;
  color: #fff;
  font-size: clamp(1.1rem,4vw,1.8rem);
  text-align: center;
  padding: 4rem 0;
  font-weight: 300;
}
.green-bg {
  background-color: var(--brand-light-green);
  color: var(--brand-white);
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.12em;
  font-size: clamp(1.2rem,5vw,2rem);
  text-transform: uppercase;
  padding: 1rem;
}
.site-footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.site-footer .container img {
  max-width: 30vw;
}
.site-footer .container img + img {
  margin-top: 1rem;
}
.site-footer nav.container {
  text-align: center;
  padding: 10px;
  display: block;
}
.site-footer a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}
.content {
  line-height: 1.3;
  margin-top: 4rem;
}
.callendar img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.callendar .content {
  display: none;
}
.callendar .head, .callendar .row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;
  margin-bottom: 10px;
}
.callendar .row div {
  height: clamp(60px,8vw,100px);
  overflow: hidden;
  cursor: pointer;
}
.callendar .head {
  margin-bottom: 20px;
}
.callendar .head div {
  background-color: #000;
  color: #fff;
  padding: 10px;
  font-weight: 800;
  text-align: center;
  font-size: clamp(0.6rem,2vw,2rem);
}
.callendar .row {
  position: relative;
}
.callendar .row:before {
  font-weight: 800;
  position: absolute;
  z-index: 9;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(1rem,5vw,3rem);
  color: #fff;
  text-shadow: 0 0 4px #000;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
.callendar .callendar-1:before {
  content: "na gotowanie";
  background-color: rgba(64, 48, 6, 0.57);
}
.callendar .callendar-2:before {
  content: "na podróżowanie";
  background-color: rgba(255, 255, 0, 0.57);
}
.callendar .callendar-3:before {
  content: "na dbanie o higienę";
  background-color: rgba(0, 222, 255, 0.57);
}
.callendar .callendar-4:before {
  content: "na sprzątanie";
  background-color: rgba(0, 255, 42, 0.57);
}
.callendar .callendar-5:before {
  content: "na co dzień";
  background-color: rgba(87, 87, 87, 0.57);
}
.for-nature .brand {
  background-color: #ebebeb;
  margin-bottom: 40px;
  padding: 0;
}
.for-nature .brand .container {
  position: relative;
  text-align: center;
}
.for-nature .brand img {
  position: absolute;
  left: 0;
  top: 0;
  width: 30vw;
  max-width: 250px;
}
.for-nature .brand h2 {
  padding: 5px 100px;
}
.for-nature .with-bg {
  display: flex;
  justify-content: center;
}
.for-nature .with-bg .content {
  color: var(--brand-green);
  text-align: center;
  max-width: 60ch;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}
.for-nature .with-bg .content p {
  font-size: clamp(1rem,2vw,1.2rem);
}
@media screen and (max-width: 35em) {
  .for-nature .with-bg {
    background-image: none;
    min-height: unset;
    padding-top: 0;
  }
}
.modal {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  z-index: 99;
  max-width: 80vw;
  margin: 0 20px;
  padding: 20px;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
}
.modal .close {
  border: none;
  font-weight: 800;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 3px;
  display: block;
  z-index: 10;
  width: 25px;
  height: 25px;
}
.modal .wrap {
  overflow: auto;
  position: relative;
  z-index: 3;
}
.modal header {
  height: 50%;
  min-height: 150px;
  max-height: 550px;
  overflow: hidden;
  position: relative;
}
.modal header::after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
  background-color: #403006;
}
.modal header img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.modal.visible {
  opacity: 1;
  pointer-events: all;
}
.modal nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.modal nav span {
  position: absolute;
  left: 50%;
  z-index: 0;
  color: #f4f4f4;
  font-weight: 800;
  font-size: 5rem;
  transform: translateX(-50%);
  top: -6rem;
}
.modal nav button {
  position: relative;
  z-index: 1;
  border: none;
  background-color: var(--brand-green);
  height: 30px;
  width: 50px;
  display: inline-block;
  margin: 3px;
  color: #fff;
  border-radius: 3px;
  transition: background-color 200ms, color 200ms;
  outline: none;
}
.modal .section1 button, .modal header.section1::after {
  background-color: #403006;
}
.modal .section2 button, .modal header.section2::after {
  background-color: yellow;
  color: #575757;
}
.modal .section3 button, .modal header.section3::after {
  background-color: #00deff;
}
.modal .section4 button, .modal header.section4::after {
  background-color: #00ff2a;
}
.modal .section5 button, .modal header.section5::after {
  background-color: #575757;
}
@media screen and (max-width: 35em) {
  .modal {
    left: 10px;
    transform: translateX(0);
    top: 10px;
  }
  .modal .inner {
    overflow: scroll;
  }
}
.eko {
  padding: 50px 20px;
}
.eko h2 {
  font-size: 2rem;
  color: var(--brand-green);
}
.eko h2 span {
  color: #c1c6ca;
}
@media screen and (max-width: 35em) {
  .eko {
    overflow: scroll;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.carousel {
  display: grid;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 4rem 0;
}
.item {
  border-radius: 2px;
  box-shadow: 5px 6px 6px rgba(0, 0, 0, 0.11);
  padding: 10px;
  border: 1px solid #ebebeb;
  margin: 0 20px;
}
.item h4 {
  color: var(--brand-green);
}
.item figure {
  text-align: center;
}
.item p {
  font-size: 0.9em;
}
.amount-value {
  font-family: Cookie;
  font-size: 3rem;
  text-align: center;
  color: var(--brand-green);
  margin-top: 1rem;
}
.numbers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.numbers .number {
  flex-basis: 25%;
  padding: 1rem;
  min-width: 250px;
  flex-grow: 1;
}
.numbers span {
  display: block;
  font-size: 3rem;
  font-weight: bold;
}
/*# sourceMappingURL=index.2a7023ff.css.map */
