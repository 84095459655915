.site-header {
	position: fixed;
	z-index: 10;
	background-color: var(--brand-green);
	width: 100%;

	a {
		color: #fff;
		font-weight: 800;
		text-transform: uppercase;
		text-decoration: none;
		padding:0 10px;
		display: inline-block;
		position: relative;
		transition: top 300ms;
		top:0;

		&:hover {
			top: -3px;
		}
	}

	nav {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
	}

	@media screen and (max-width: $size__mobile){
		left: 0;
		bottom: 0;
		right: 0;
		transition: transform 400ms;
		transform: translatey(100%);

		.toggled & {
			transform: translateY(0);
		}

		nav {
			flex-direction: column;
			a {
				margin-bottom: 5px;
			}
		}
	}
}

.menu-toggle {
	border-radius: 50%;
	width: 50px;
	height: 50px;
	border: none;
	position: fixed;
	right: 10px;
	bottom: 10px;
	background-color: var(--brand-green);
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	color: #fff;
	line-height: 0;
	outline: none;
	transition: all 300ms;

	.toggled & {
		color: var(--brand-green);
		background-color: #fff;
	}
}
