fieldset {
	border: none;
	min-width: 220px;
}

.form {
	background: var(--brand-lightgray);
	margin-left: -2rem;
	margin-right: -2rem;
	padding-left: 2rem;
	padding-right: 2rem;
	padding-top: 4rem;
	padding-bottom: 4rem;

	@media screen and (max-width: $size__mobile){
		margin-left: -1rem;
		margin-right: -1rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	&__row {
		margin-bottom: 15px;

		.btn,
		input:not([type]),
		input[type=datetime],
		input[type=email],
		input[type=number],
		input[type=password],
		input[type=search],
		input[type=tel],
		input[type=text],
		input[type=url],
		textarea {
			// width: 100%;
			display: block;
		}

		textarea {
			max-width: 100%;
			min-height: 15px;
			width: 100%;
			min-height: 100px;
			border-radius: 20px;
		}
	}

	&__label {}
}
// Input text and textarea ================================================
input:not([type]),
input[type=datetime],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
textarea ,select{
	background-color: $input_bg_color;
	border: 1px solid $input_border_color;
	padding: 6px 15px;
	outline: none;
	font-size: 16px;
	font-weight: 300;
	font-family: $main_font_family;
	border-radius: 100px;
	transition: all 0.3s;

	&:hover {
		border: 1px solid $input_hover_border_color;
		background-color: $input_hover_bg_color;
	}

	&:focus {
		border: 1px solid $input_focus_border_color;
		background-color: $input_focus_bg_color;
	}

	&.invalid {
		background-color: #fff0f0;
		border: 1px solid red;
	}
	// Placeholder for input +++++++++++++++
	@include placeholder {
		color: #ddd;
	}
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/* Для Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	height: auto;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
/* Для Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

// Input checkbox and radio ===============================================
input[type=checkbox],
input[type=radio] {
	padding: 0;
	display: inline-block;
	height: 14px;
	width: 14px;
	border: 1px solid #aaa;
	overflow: hidden;
	margin-top: -4px;
	vertical-align: middle;
	-webkit-appearance: none;
	outline: 0;
	background: 0 0;
	text-align: center;
	position: relative;
	flex: none;

	&:not(:disabled),
	&:not(:disabled) {
		cursor: pointer;
	}

	&:before,
	&:before {
		display: block;
	}

	&:disabled,
	&:disabled {
		border-color: #ddd;
	}
}

input[type=radio] {
	border-radius: 50%;

	&:checked:before {
		content: '';
		width: 6px;
		height: 6px;
		top: 50%;
		left: 50%;
		margin-top: -3px;
		margin-left: -3px;
		display: block;
		position: absolute;
		border-radius: 50%;
		background: #000;
	}

	&:disabled:checked:before {
		background-color: #aaa;
	}
}

input[type=checkbox] {
	&:checked:before,
	&:indeterminate:before {
		content: "\2714";
		font-size: 12px;
		-webkit-font-smoothing: antialiased;
		text-align: center;
		line-height: 13px;
		color: #000;
	}

	&:indeterminate:before {
		content: "\f068";
	}

	&:disabled:checked:before,
	&:disabled:indeterminate:before {
		color: #aaa;
	}
}

label {
	margin-bottom: 10px;
	display: inline-block;
	strong {
		display: block;
		font-weight: 800;
	}
}
select {
	max-width: 100%;
}

.order-form {
	display: flex;
	flex-wrap: wrap;

	.form__row {
		flex-basis: 50%;
		flex-grow: 1;
	}

	@media screen and (min-width: $size__mobile){
		.form__row {
			display: flex;
			align-items: center;
			flex-basis: 50%;
			flex-direction: column;
			position: relative;
		}

		.form__row:first-child {
			padding-right: 2rem;
			&:after {
				content: '≈';
				font-size: 3rem;
				position: absolute;
				right: -1rem;
				top: 50%;
			}
		}
		.form__row + .form__row {
			padding-left: 2rem;
		}

	}

	input {
		text-align: center;
		font-weight: 800;
		font-size: 1.2rem;
	}


	figure {
		display: flex;
		align-items: center;
	}
}


.contact-form {
	display: flex;
	flex-wrap: wrap;
	h3 {
		margin-bottom: 0.51rem;
	}

	fieldset:first-child {
		flex-basis: 35%;
		flex-grow: 1;
		position: relative;
		margin-right: 3rem;

		.form__row figure{
			align-items: center;
			display: flex;

			svg {
				margin-right: 1rem;
			}

			label {
				// padding-top: 1rem;
			}
		}

		&:before,
		&:after {
			display: inline-block;
			position: absolute;
			right: 0;
		}

		&:before {
			content: 'lub';
			font-weight: 300;
			top: 2em;
		}

		&:after {
			height: 70px;
			width: 1px;
			background-color: var(--brand-gray);
			top: 2em;
		}
	}

	fieldset + fieldset {
		flex-basis: 60%;
		padding-left: 3rem;
	}

	@media screen and (max-width: $size__mobile){

		fieldset {
			margin-right: 0;
			padding-left: 0;

			&:first-child:before {
				left: 50%;
				bottom: -1rem;
				top: auto;
			}
		}

		fieldset + fieldset {padding-left: 0;margin-top: 2rem;}
	}
}

