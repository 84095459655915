@keyframes countDown_flip_1 {
    0% {
        transform: rotateX(0deg);
        z-index:1;
    }
    100% {
        transform: rotateX(-180deg);
        z-index:0;
    }
}

@keyframes countDown_flip_2 {
    0% {
        transform: rotateX(0deg);
        z-index:0;
    }
    100% {
        transform: rotateX(-180deg);
        z-index:1;
    }
}

.counter {
    overflow: hidden;
}

.countDown__ {
    &cont {
        font-size: clamp(0.2rem,1.5vw,1rem);
        display: flex;
        justify-content: center;
        color: $digit_color;
        // overflow: hidden;
    }

    &interval {
        &_cont {
            display: flex;
            justify-content: space-around;
            width: $interval_container_size;

            &:nth-child(n+1):not(:last-child) {
                margin-right: clamp(0,1vw,2rem);
            }
        }

        &_basic_cont {
            display: flex;
            flex-direction: column-reverse;

            &_description {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: clamp(1rem,2vw,2rem);
                margin-bottom: 1rem;
            }
        }
    }

    &digit_cont {
        perspective: $digit_height * 2;
        width: $digit_width;
        height: $digit_height;
        position: relative;
        line-height: $digit_line_height;
        font-size: $digit_size;
        font-weight: bold;
        border-radius: $border_radius_size;
        border: clamp(1px,0.5vw,5px) solid black;
        margin:0 .5em;

        &:before,&:after {
            content: '';
            position: absolute;
            display: block;
            background-image: url(../images/clockimage.png);
            width: 7px;
            height: 20px;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
        }

        &:before {
            left: -8px;
        }

        &:after {
            right: -11px;
        }

        &:nth-child(n+1):not(:last-child) {
            margin-right: .05em
        }
    }

    &digit_last_placeholder, &digit_new_placeholder {
        position: absolute;
        left: 0;
        width: 100%;
        height: 50%;
        text-align: center;
        overflow: hidden;
    }
    &digit_last_placeholder {
        bottom: 0;
        background: $color-bottom;
        border-radius: 0 0 $border_radius_size $border_radius_size;
        &_inner {
            @include half_content_bottom();
        }
    }
    &digit_new_placeholder {
        top: 0;
        background: $color-top;
        border-radius: $border_radius_size $border_radius_size 0 0;
    }

    &digit_last_rotate, &digit_new_rotate {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 50%;
        font-weight: bold;
        position: absolute;
        top: 0;
        overflow: hidden;
        animation-duration: $animation_duration;
        animation-timing-function: linear;
        border-radius: $border_radius_size $border_radius_size 0 0;
        animation-fill-mode: forwards;
        transform-origin: 100% 100%;

        &:after{
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-bottom: $digit_middle_line_size solid rgba(0, 0, 0, 0.1);
        }
    }

    &digit_last_rotate {
        animation-name: countDown_flip_1;
        background: $color-top;
    }

    &digit_new_rotate {
        animation-name: countDown_flip_2;
        background: $color-bottom;
    }

    &digit_new_rotated {
        transform: rotateX(180deg);
        width: 100%;
        height: 100%;
        &_inner {
            @include half_content_bottom();
        }
    }

}
