/* Global variables */
/* ======================================================================== */
$main_font_family: "Open Sans";
$main_color: #000;
$main_line_height: 1.2;
$main_font_size: 16px;

$size__mobile: 35em;
$container__width: 70rem;
$size__spacing: 4rem;

$footer_height: auto;

// Color collection for elements
$color_primary: #7b8287;
$color_success: #36a566;
$color_info: #03a9f4;
$color_warning: #fca33e;
$color_danger: #ff1a1a;
$color_withe: #ffffff;

$el-colors: (
	primary: $color_primary, // color for .element__primary
	success: $color_success, // color for .element__success
	info: $color_info, // color for .element__info
	warning: $color_warning, // color for .element__warning
	danger: $color_danger, // color for .element__danger
);

/* Grid */
/* ======================================================================== */
$grid_padding: 20px;

/* Form */
/* ======================================================================== */
// Input variables

// Input color
$input_color: $main_color;
$input_bg_color: #fff;
$input_border_color: #ddd;
$input_placeholder_color: #ddd;

// Input hover color
$input_hover_color: #fff;
$input_hover_bg_color: #fff;
$input_hover_border_color: #ddd;
$input_hover_placeholder_color: #ddd;

// Input focus color
$input_focus_color: #fff;
$input_focus_bg_color: #fff;
$input_focus_border_color: #ddd;
$input_focus_placeholder_color: #ddd;

// Input disabled color
$input_disabled_color: #fff;
$input_disabled_bg_color: #ccc;
$input_disabled_border_color: #ccc;
$input_disabled_placeholder_color: #fff;

// Input error color
$input_error_color: red;
$input_error_bg_color: #fff;
$input_error_border_color: red;
$input_error_placeholder_color: red;

// Button variables
$btn_default_bg_color: #ddd;
$btn_default_text_color: $main_color;
$btn_contrast_text_color: $color_withe;
$btn_default_bg_color_hover: #ccc;

/* Breakpoints */
$breakpoint-small: 480px !default;
$breakpoint-medium: 760px !default;
$breakpoint-large: 960px !default;
$breakpoint-xlarge: 1200px !default;

$breakpoint-mini-max: ($breakpoint-small - 1) !default;
$breakpoint-small-max: ($breakpoint-medium - 1) !default;
$breakpoint-medium-max: ($breakpoint-large - 1) !default;
$breakpoint-large-max: ($breakpoint-xlarge - 1) !default;


// countdown

$global_size: 13px;
$animation_duration: 1s;
$color-top: #f7f7f7;
$color-bottom: white;

$interval_container_size: auto; //$minimum_interval_cont_width + 3em;
$intervals_space_between: 2rem;

$border_radius_size:  10px;

$digit_height: 1.6em;
$digit_width: 1em;
$digit_size: 5em;
$digit_middle_line_size:  01em;
$digit_color: #333;

$descriptionColor: white;
$descriptionMarginTop:  3em;
$descriptionSize: 1.2em;

// == Don't touch ==
$minimum_interval_cont_width: 6 * $digit_width;
$digit_line_height: $digit_height;


:root {
	--brand-blue: #0b77b9;
	--brand-green: #407c3b;
	--brand-light-green: #699013;
	--brand-white: #fff;
	--brand-lightgray: #F7F7F7;
	--brand-gray: #A7A8A8;
	--brand-black: #000;
}

